.links-container {
  margin: 100px;
  padding: 0px 10%;
}

.links-container h1 {
  padding-bottom: 60px;
  color: white;
}

.links-icon {
  display: block;
  font-size: 200%;
  color: #6A0F49;
}

.links-icons {
  display: flex;
  justify-content: space-around;
}
