.footer-container {
  display: flex;
  justify-content: space-around;
  background-color: #151515;
  padding: 20px;
}

.footer-container p {
  font-size: 12px;
 color: #9c9fa8;
}

.contact-heading {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
  color: white;
}

.footer-container a{
  text-decoration: none;
 color: #9c9fa8;
}
